import React from "react";
import { Container, Fab,  Typography, styled } from "@mui/material";
// import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import bannerBg from "../../Assets/bannerBg.mp4";
// import ArrowRoundIcon from "../../Assets/Icons/arrowRound";
import {  useNavigate } from "react-router-dom";

const BannerWrapper = styled("section")(() => ({ width: '100%', top: '5.1875rem' }));
const BannerStyle = styled("div")(() => ({ width: '100%', borderRadius: '1.875rem', backgroundColor: '#000B12', paddingInline: '1.875rem', paddingBlock: '1.25rem', position: 'relative', overflow: 'hidden', 
  '@media (max-width: 550px)': {
    borderRadius: 0,
  },
}));
const BannerVideo = styled("div")(() => ({
  position: 'absolute', zIndex: 1, width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', left: 0, top: 0, pointerEvents: 'none',backgroundColor: '#000B12',
  '& video': { width: '100%', height: '90%', mixBlendMode: 'lighten', opacity: 0.5, WebkitFilter: 'hue-rotate(180deg)saturate(100%)'},
  // '&:after': {
  //   content: `''`, position: 'absolute', width: '100%', height: '100%', left: '0%', top: '0%',background: '#26BEFF',mixBlendMode: 'hue',
  // }
  '@media (max-width: 550px)': {
    '& video': { transform:'scale(1.4)'}
  },
}));
const BannerRound = styled(motion.div)(() => ({
  position: 'absolute', width: '120%', height: 0, paddingBlockStart: '120%', left: '-10%', top: '-20%', pointerEvents: 'none', borderRadius: '50%', border: '0.0625rem solid #0C1A1F', background: 'linear-gradient(332deg, #000B12 12.94%, #00111C 83.81%), #D9D9D9',boxShadow:'0 0 1.25rem #000B12 ',
  '&:before': {
    content: `''`, position: 'absolute', width: '70%', height: '70%', left: '15%', top: '10%', borderRadius: '50%', border: '0.0625rem solid #0C1A1F', background: 'linear-gradient(332deg, #000B12 12.94%, #001421 83.81%)',boxShadow:'0 0 1.25rem #000B12 ',
  },
  '&:after': {
    content: `''`, position: 'absolute', width: '50%', height: '50%', left: '25%', top: '20%', borderRadius: '50%', border: '0.0625rem solid #0C1A1F', background: 'linear-gradient(332deg, #000B12 12.94%, #011624 83.81%)',boxShadow:'0 0 1.25rem #000B12 ',
  }
}));

const BannerContent = styled("div")(() => ({ position: 'relative', zIndex: 2 }));
// const NavBox = styled("div")(() => ({
//   width: '100%', display: 'flex', alignItems: 'center', gap: '1.875rem', justifyContent: 'flex-end',
//   '& .navlink': {
//     color: '#fff', position: 'relative',
//     '&.active': {
//       '&:after': { content: `''`, position: 'absolute', left: 0, bottom: '-0.5rem', width: '100%', height: '0.125rem', backgroundColor: '#0F9AD6', borderRadius: '0.125rem', }
//     }
//   }
// }));
const BannerText = styled("div")(() => ({ width: '100%', maxWidth: '75rem', padding: '6.25rem 3.75rem', marginInline: 'auto', display: 'flex', flexDirection: 'column', alignItems: 'center', alignContent: 'center', textAlign: 'center', gap: '1.25rem' ,
  '@media (max-width: 768px)': {
    padding: '6.25rem',
  },
  '@media (max-width: 550px)': {
    padding: '1.875rem 0',
  },

}));
const BannerHeading = styled("div")(() => ({ color: '#fff', fontSize: '4.375rem', lineHeight: 1.25, fontWeight: 400, textAlign: 'center', textTransform: 'capitalize',
  '@media (max-width: 1370px)': {
    fontSize: '3.75rem', 
  },
  '@media (max-width: 768px)': {
    fontSize: '2.5rem', 
  },
  '@media (max-width: 550px)': {
    fontSize: '1.375rem', 
  },
}));
const BannerTextGredient = styled("span")(() => ({ fontWeight: 600, marginInline: '1rem', background: 'linear-gradient(to right, #0B99FF -30%, #fff 100%)', backgroundClip: 'text', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', 
  '@media (max-width: 550px)': {
    marginInline: '0.5rem', 
  },
}));
const btnStyle = {background: 'linear-gradient(271deg, #FFF 15.14%, #0B99FF 137.79%)',color:'#000',paddingInline:'2.5rem',fontSize:'1.25rem',fontWeight:400,};

export default function BannerLanding() {
  const router = useNavigate();
  return (
    <>
      <BannerWrapper>
        <Container maxWidth="xl">
          <BannerStyle>
            <BannerVideo>
              <BannerRound
                initial={{ scale: 0, rotate: 0 }}
                animate={{ scale: 1, rotate: 360 }}
                transition={{ duration: 1, ease: "easeInOut" }}
              />
              <motion.video
                controls={false}
                autoPlay
                muted
                loop
                initial={{ opacity: 0 }}
                animate={{ opacity: 0.5 }}
                transition={{ delay: 1, duration: 1 }}
              >
                <source src={bannerBg} type="video/mp4" />
              </motion.video>
            </BannerVideo>
            <BannerContent>
              {/* <NavBox>
                <motion.div
                  initial={{ y: -30, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.5, ease: "easeInOut", delay: 0.5 }}
                >
                  <Link className="navlink active">Home</Link>
                </motion.div>
                <motion.div
                  initial={{ y: -30, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.5, ease: "easeInOut", delay: .7 }}
                >
                  <Link className="navlink">Consumers</Link>
                </motion.div>
              </NavBox> */}
              <BannerText>
                <BannerHeading>
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.75, ease: "easeInOut", delay: 1, staggerChildren: 0.3 }}
                  >
                    <BannerTextGredient>Modern </BannerTextGredient>
                    Financial
                    <BannerTextGredient>Ecosystem</BannerTextGredient>
                    <br />
                    Built for the Digital Age
                  </motion.div>
                </BannerHeading>
                <motion.div
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 1, ease: "easeInOut", delay: 1.3 }}
                >
                  <Typography variant="body1" my={0} sx={{ color: '#fff!important' }}>{`A combination of the stability and trust of a decades old financial institution with the agility and modern flexibility of emerging technology.`}</Typography>
                </motion.div>
                <motion.div
                  // whileHover={{ scale: 1.1, color: '#0F9AD6' }}
                  // transition={{ duration: 0.3 }}
                  initial={{ y: 30, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 1, ease: "easeInOut", delay: 1.75 }}
                >
                  <motion.div
                    whileHover={{ scale: 1.1, color: '#0F9AD6' }}
                    transition={{ duration: 0.3,}}
                  >
                    {/* <Link style={{ display: 'flex', alignItems: 'center', color: '#fff', gap: '0.75rem' }}>
                      <SvgIcon viewBox="0 0 53 51" sx={{ width: "3.125rem", height: "3.125rem" }}>
                        <ArrowRoundIcon />
                      </SvgIcon>
                      <motion.div
                        initial={{ x: -5 }}
                        animate={{ x: 0 }}
                        transition={{ duration: 0.5, repeat: Infinity, repeatType: "reverse" }}
                      >
                      Get Started
                      </motion.div>
                    </Link> */}
                    <Fab variant="extended" style={btnStyle} sx={{fontSize:{xs:'1rem!important',lg:''}}} onClick={() => router("/login")}>
                      Get Started
                    </Fab>
                  </motion.div>
                </motion.div>
              </BannerText>
            </BannerContent>
          </BannerStyle>
        </Container>
      </BannerWrapper>
    </>
  );
}
