import Axios from "../config/Axios";

export async function logout() {
  try {
    await Axios().post(`/auth/logout`);
  } catch (error) {
    console.error("Logout failed", error);
  }
  if ("BroadcastChannel" in window) {
    const bc = new BroadcastChannel("auth_channel");
    bc.postMessage("logout");
  } else {
    localStorage.setItem("logout", Date.now());
  }

  // Redirect to login page
  window.location.href = "/";
}

export function initializeCommunicationChannels() {
  if ("BroadcastChannel" in window) {
    const bc = new BroadcastChannel("auth_channel");

    bc.onmessage = (event) => {
      if (event.data === "logout") {
        // Handle forced logout
        handleLogout();
      }
    };
  } else {
    window.addEventListener("storage", (event) => {
      if (event.key === "logout") {
        // Handle forced logout
        handleLogout();
      }
    });
  }
}
function handleLogout() {
  localStorage.removeItem("logout");
  window.location.href = "/";
}
