import React from "react";
import { Box, Container, Grid, Typography, styled } from "@mui/material";
import { motion } from "framer-motion";
import video from "../../Assets/finance-video.mp4";
import useWindowWidth from "../../utils/useWindowWidth";
import FinanceStapes from "./financeStapes";
const ModuleWrapper = styled("section")(() => ({
  width: '100%', paddingBlock: '6.25rem', position: 'relative', overflow: 'hidden', background: 'linear-gradient(180deg, #E9EEFF 0%, #FFF 100%)',
  '@media (max-width: 550px)': {
    paddingBlock: '2.5rem 1.25rem',paddingInline:'1.25rem',
  },
}));
const VideoWrapper = styled("div")(() => ({
  position: 'relative', marginBlockStart: '5rem',
  '&:before':{content:`''`,position:'absolute',width:'100%',height:'120%',top:'-10%',left:'-40%', borderRadius: '1.875rem', background: 'linear-gradient(332deg, #000B12 12.94%, #00111C 83.81%)',},
  '@media (max-width: 550px)': {
    marginBlockStart: '2.5rem',
  },
}));
const VideoBox = styled("div")(() => ({
  width: '100%',height: '30rem', borderRadius: '1.25rem', overflow: 'hidden', position: 'relative',border:'#fff solid 0.125rem',
  '& video': { width: '100%', height: '100%', objectFit: "cover" },
  '@media (max-width: 550px)': {
    height: '13.75rem',
  },
}));
export default function FinanceModule() {
  const { isMobile } = useWindowWidth();
  return (
    <>
      <ModuleWrapper>
        <Container maxWidth="xl">
          <Box textAlign={'center'}>
            {!isMobile && (<></>)}
            <Typography variant="h2" className="titel text-gradient" mb={0} px={{ xs: 5, lg: 0 }}>
              {/* Funds at your Fingertips */}
              Funds at your Fingertips
            </Typography>
          </Box>
          <Grid container py={{ xs: 2, lg: 5 }} position={'relative'}   spacing={2}>
          {!isMobile && (<>
            <Grid item xs={12} lg={6}>
              <VideoWrapper>
                <VideoBox>
                  <motion.video
                    controls={false}
                    autoPlay
                    muted
                    loop
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 1, duration: 1 }}
                  >
                    <source src={video} type="video/mp4" />
                  </motion.video>
                </VideoBox>
              </VideoWrapper>
            </Grid>
          
          </>)}
            <Grid item xs={12} lg={6}>
              {/* <FinanceStape/> */}
              <FinanceStapes/>
            </Grid>
          </Grid>
        </Container>
      </ModuleWrapper>
    </>
  );
}
