import React from "react";
export default function LandingWave() {
    return (
        <>
            <svg viewBox="0 0 522 166" fill="none">
                <path id="animated-path" opacity="0.5" d="M3 33.9591C6.95948 16.6336 22.9558 -11.9306 55.2652 12.4162C95.6519 42.8498 139.84 174.5 181.652 162.19C223.464 149.88 239.144 23.7006 289.983 23.7006C340.823 23.7006 338.923 124.234 394.989 106.794C451.055 89.355 461.033 50.3727 519 49.0049" stroke="url(#paint0_linear_15591_604)" strokeWidth="5" strokeLinecap="round">
                    <animate attributeName="stroke-dasharray" values="0, 1000; 1000, 0; 0, 1000" keyTimes="0; 0.5; 1" dur="10s" repeatCount="indefinite" />
                    <animate attributeName="stroke-dashoffset" values="0; 0; 0" keyTimes="0; 0.5; 1" dur="10s" repeatCount="indefinite"/>
                </path>
                <defs>
                    <linearGradient id="paint0_linear_15591_604" x1="3.00001" y1="34.5058" x2="492.706" y2="136.722" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white"/>
                    <stop offset="0.115" stopColor="#457EA2"/>
                    <stop offset="0.47039" stopColor="#FAE19F"/>
                    <stop offset="0.671798" stopColor="#1F459F"/>
                    <stop offset="0.945975" stopColor="white"/>
                    </linearGradient>
                </defs>
            </svg>
        </>

    );
}
