import React from "react";
import { Box, Container, Grid, Typography, styled } from "@mui/material";
import LandingWave from "./landingWave";
// import ShareScreen from "../../Assets/image/share-screen.png"
import video from "../../Assets/trust-video.mp4";
import Image from "../../Assets/image/trust-img.png";

import CircleBox from "../../Assets/Svgs/circleBox";
import LandingTrust from "./landingTrust";
import useWindowWidth from "../../utils/useWindowWidth";
import LandingTrustMobile from "./landingTrustMobile";
const ModuleWrapper = styled("section")(() => ({
  width: '100%', paddingBlock: '3.75rem 12.5rem', backgroundColor: '#fff', position: 'relative',overflow:'hidden',
  '@media (max-width: 550px)': {
    paddingBlock:'2.5rem 1.25rem',paddingInline:'1.25rem',
  },
}));



const LeftWave = styled("div")(() => ({ width: '32.5rem', height: '10rem', position: 'absolute', left: '0', bottom: '15%',transform:'rotate(-25deg)',}));
const RightWave = styled("div")(() => ({ width: '32.5rem', height: '10rem', position: 'absolute', right: '15%', top: '30%', }));


const VideoWrapper = styled("div")(() => ({width: '100%', position:'relative',zIndex:2,}));
const VideoBox = styled("div")(() => ({height:'25rem',borderRadius:'12.5em 1.25rem',overflow:'hidden', position:'relative',
  '& video, & img':  { width: '100%', height: '100%',objectFit:"cover"},
  '@media (max-width: 550px)': {
    height:'13.75rem',borderRadius:'5em 0 12.5rem 0',
  },
}));
const CBTop = styled("div")(() => ({width: '7.5rem',height:'6.25rem', position:'absolute',right:'-2.5rem',top:'-2.5rem',
    '@media (max-width: 550px)': {
      width: '5rem',height:'3.75rem',right:'-1.25rem',top:'-1.25rem',
    },
}));
const CBBottom = styled("div")(() => ({width: '7.5rem',height:'6.25rem', position:'absolute',right:'20%',bottom:'-2.5rem',
'@media (max-width: 550px)': {
  width: '5rem',height:'3.75rem',right:'-1.25rem',bottom:'-1.25rem',
},
}));

const ModuleContainer = { position: 'relative', }
export default function TrustSection() {
  const { isMobile } = useWindowWidth(); 
  return (
    <>
      <ModuleWrapper>
        <Container maxWidth="xl" style={ModuleContainer}>

          <Box textAlign={'center'}>

            <Typography variant="h2" className="titel text-gradient" textAlign={'center'}  mb={0}>
            80+ years of Trust, Security, and Community service
            </Typography>
          </Box>
          {!isMobile &&(
            <>
            <LeftWave>
              <LandingWave />
            </LeftWave>
            <RightWave>
              <LandingWave />
            </RightWave>
            </>
          )}
          <Grid container py={{xs:2,lg:5}}  position={'relative'}  alignItems={'center'}> 
            
            <Grid item xs={12} lg={6}>
              <VideoWrapper>
                <CBTop>
                  <CircleBox/>
                </CBTop>
                <CBBottom>
                  <CircleBox/>
                </CBBottom>
                <VideoBox>
                  
                  {!isMobile ? (
                    <video
                      controls={false}
                      autoPlay
                      muted
                      loop
                    >
                      <source src={video} type="video/mp4" />
                    </video>
                  ):(
                    <img src={Image} alt="Image"/>
                  )}
                </VideoBox>
                
              </VideoWrapper>
            </Grid>
            <Grid item xs={12} lg={6} >
              {isMobile ?(
                <LandingTrustMobile/>
              ):(
                <LandingTrust/>
              )} 
            </Grid>
            
            
          </Grid>
        </Container>

      </ModuleWrapper>
    </>
  );
}
