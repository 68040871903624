import React from "react";
import AppRouter from "./routes/AppRouter";
import { ThemeProvider } from "@mui/material";
import { BrowserRouter as Router } from "react-router-dom";
import theme from "./theme";
import { CustomerContextProvider } from "./context/customerContext";
import { ProjectGuideContextProvider } from "./context/projectGuideContext";
import UpdateNotification from "./UpdateNotification";
import AuthWrapper from "./components/AuthWrapper";
import ErrorBoundary from "./components/ErrorBoundary";

function App() {
  return (
    <ErrorBoundary>
      <Router>
        <AuthWrapper>
          <CustomerContextProvider>
            <ProjectGuideContextProvider>
              <ThemeProvider theme={theme}>
                <UpdateNotification />
                <AppRouter />
              </ThemeProvider>
            </ProjectGuideContextProvider>
          </CustomerContextProvider>
        </AuthWrapper>
      </Router>
    </ErrorBoundary>
  );
}

export default App;
