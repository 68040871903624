import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  Container,
  Fab,
  styled,
  SvgIcon,
  Typography,
} from "@mui/material";
import GainLogo from "../../svg/Gainlogo";
import EmailIcon from "../../svg/emailIcon";
import CallIcon from "../../svg/callIcon";
import useCustomerStore from "../../stores/useRefreshStore";

const Brand = styled("div")(() => ({
  width: "12.5rem",
  "@media (max-width: 550px)": {
    width: "7.5rem",
  },
}));
const Header = styled("header")(() => ({
  position: "sticky",
  top: 0,
  left: 0,
  width: "100%",
  paddingBlock: "1.25rem",
  zIndex: 10,
  backgroundColor: "transparent",
  "&.sticky": {
    backgroundColor: "rgba(255,255,255,1)",
    backdropFilter: "blur(0.0625rem)",
    boxShadow: "rgba(0,0,0,.15) 0 0 1.25rem",
  },
  "@media (max-width: 550px)": {
    padding: " 0.625rem 1.25rem",
  },
}));
export default function HeaderLanding() {
  const router = useNavigate();
  const store = useCustomerStore();
  const [name, setName] = React.useState("");
  const customer = store.customer;

  useEffect(() => {
    if (customer) {
      let splitFullName = customer?.fullName?.split(" ");
      let firstChar =
        customer?.firstName?.substring(0, 1) ||
        splitFullName[0]?.substring(0, 1);
      let lastChar =
        customer?.lastName?.substring(0, 1) ||
        splitFullName[splitFullName?.length - 1]?.substring(0, 1);
      let fullName = firstChar + lastChar;
      setName(fullName.toUpperCase());
    }
  }, [customer?.customer_external_id]);

  const btnStyle = {
    minWidth: "0",
    background: "linear-gradient(346deg, #001640 -8.91%, #0098FF 136.27%)",
    padding: " 0.75rem 1.875rem",
    color: "#fff",
    fontSize: "0.9375rem",
    height: "2.75rem",
    borderRadius: "1.5rem",
  };

  React.useEffect(() => {
    const dynamicElement = document.getElementById("header");
    const handleScroll = () => {
      if (window.scrollY > 0) {
        dynamicElement.classList.add("sticky");
      } else {
        dynamicElement.classList.remove("sticky");
      }
    };
    window.addEventListener("scroll", handleScroll);
    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <Header id="header">
        <Container
          maxWidth="xl"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "0.625rem 1.25rem",
            flexWrap: "wrap",
          }}
        >
          <Brand>
            <Link to={`/`}>
              {/* <XferLogo /> */}
              <GainLogo />
            </Link>
          </Brand>
          <Box
            display={"inline-flex"}
            gap={"1.25rem"}
            alignItems={"center"}
            justifyContent={"space-between"}
            width={{ xs: "100%", lg: "auto" }}
            ml={{ xs: 0, lg: "auto" }}
            order={{ xs: 1, lg: 0 }}
          >
            <Typography
              variant=""
              component={"a"}
              target="_blank"
              href="mailto:support@gainfunds.com"
              display={"flex"}
              alignItems={"center"}
              gap={1.125}
              fontSize={{ xs: "0.75rem", lg: "" }}
            >
              <SvgIcon viewBox="0 0 17 15" sx={{ width: 16, height: 16 }}>
                <EmailIcon />
              </SvgIcon>
              support@gainfunds.com
            </Typography>
            <Typography
              variant=""
              component={"a"}
              target="_blank"
              href="tel:8557327272"
              display={"flex"}
              alignItems={"center"}
              gap={1.125}
              fontSize={{ xs: "0.75rem", lg: "" }}
            >
              <SvgIcon viewBox="0 0 17 15" sx={{ width: 16, height: 16 }}>
                <CallIcon />
              </SvgIcon>
              {`(855) 732-7272`}
            </Typography>
          </Box>
          <Box ml={{ xs: "auto", lg: 0 }}>
            {name ? (
              <Avatar onClick={() => router("/dashboard")} color="primary">
                {name}
              </Avatar>
            ) : (
              <Fab
                variant="extended"
                style={btnStyle}
                size="small"
                ml={2}
                color="primary"
                onClick={() => router("/login")}
              >
                Login
              </Fab>
            )}
          </Box>
        </Container>
      </Header>
    </>
  );
}
