import { create } from "zustand";
import Axios from "../config/Axios";
import { getCustomerInfoServices } from "../services/CustomerServices";
import { persist } from "zustand/middleware";

const PUBLIC_PATHS = [
  "/scan",
  "/license-scanner",
  "/passport-scanner",
  "/receipt",
  "/login",
  "/register",
  "/forgot-password",
  "/reset-password",
  "/verify-email",
  "/terms",
  "/terms-conditions",
  "/e-sign",
  "/p2p",
  "/p2p-qr",
  "/qrpay",
  "/qr-license-scanner",
  "/p2p-license-scanner",
  "/p2p-qr-license-scanner",
  "/p2p-qr-passport-scanner",
  "/qr-passport-scanner",
  "/verify-phone",
  "/p2p-passport-scanner",
  "/privacy",
  "/about",
  "/contact",
  "/faq",
  "/help",
  "/support",
  "/404",
  "/500",
  "/",
];

const AUTH_ERROR_MESSAGES = [
  "Forbidden",
  "Unauthorized",
  "Token Expired",
  "Token Invalid",
  "Token Blacklisted",
  "Token Revoked",
  "Token has expired and can no longer be refreshed",
  "The token has been blacklisted",
  "The token has been revoked",
];

const useCustomerStore = create(
  persist(
    (set, get) => ({
      // Auth State
      token: null,
      customer: null,
      isInvalid: false,
      isLoading: !PUBLIC_PATHS.some((path) =>
        window.location.pathname.startsWith(path),
      ),

      // Request State
      currentRequest: null,
      loadFunActivities: false,
      contactLessReqDetails: null,
      fundRequests: null,

      // Payment State
      invoiceData: null,
      signatureData: null,
      amountArray: null,

      // Communication State
      isOtpSent: false,
      contactlessRequestDetails: null,
      notifications: "",
      setSigData: (data) => set({ signatureData: data }),

      // Refresh State
      refreshTrigger: 0,
      lastRefreshTime: null,
      isRefreshing: false,
      isInitialized: false,
      setIsInitialized: (isInitialized) => set({ isInitialized }),
      authNavigate: (path) => {
        // Prevent navigation loops
        const currentPath = window.location.pathname;
        if (currentPath === path) return;

        set({ isLoading: true });

        // Add a small delay to allow state updates to complete
        setTimeout(() => {
          set({ isLoading: false });
          window.location.href = path;
        }, 100);
      },
      initialize: async () => {
        if (get().isInitialized) return;

        try {
          const isPublicPath = PUBLIC_PATHS.some((path) =>
            window.location.pathname.startsWith(path),
          );

          set({ isLoading: !isPublicPath });
          const response = await Axios().post("/handle-refresh");

          if (response?.data?.response?.response) {
            const { token, response: customerResponse } =
              response.data.response;
            const customerData = await getCustomerInfoServices(
              customerResponse.customer_external_id,
            );

            set({
              token,
              customer: { ...customerResponse, ...customerData },
              isInitialized: true,
              isLoading: false,
              isInvalid: false,
            });
          } else {
            set({
              token: null,
              customer: null,
              isInitialized: true,
              isLoading: false,
              isInvalid: true,
            });

            if (!isPublicPath) {
              get().authNavigate("/login");
            }
          }
        } catch (error) {
          console.error("Initialization failed:", error);
          const isPublicPath = PUBLIC_PATHS.some((path) =>
            window.location.pathname.startsWith(path),
          );

          set({
            token: null,
            customer: null,
            isInitialized: true,
            isLoading: false,
            isInvalid: true,
          });

          if (!isPublicPath) {
            get().authNavigate("/login");
          }
        }
      },
      // Actions
      setToken: (token) => set({ token }),
      setSendOtp: (status) => set({ sendOtp: status }),
      setCustomer: (customer) =>
        set((state) => ({ customer: { ...state.customer, ...customer } })),
      setIsLoading: (isLoading) => set({ isLoading }),
      setCurrentRequest: (request) => set({ currentRequest: request }),
      setLoadFunActivities: (status) => set({ loadFunActivities: status }),

      handleRefresh: async () => {
        const isPublicPath = PUBLIC_PATHS.some((path) =>
          window.location.pathname.startsWith(path),
        );
        try {
          set({ isLoading: !isPublicPath });
          const response = await Axios().post("/handle-refresh");

          if (!response?.data?.response?.response) {
            throw new Error("Invalid response format");
          }

          const { token, response: customerResponse } = response.data.response;
          const customerData = await getCustomerInfoServices(
            customerResponse.customer_external_id,
          );

          set({
            token,
            customer: { ...customerResponse, ...customerData },
            isLoading: false,
          });

          return { ...customerResponse, ...customerData };
        } catch (error) {
          console.error("Refresh error:", error);

          if (
            AUTH_ERROR_MESSAGES.includes(
              error?.response?.data?.response?.message,
            )
          ) {
            set({
              token: null,
              customer: null,
              isInvalid: true,
              isLoading: false,
            });
            // get().authNavigate("/login");
          } else {
            set({ isLoading: false });
          }
          throw error;
        }
      },

      logout: async () => {
        try {
          set({ isLoading: true });
          await Axios().post("/logout");

          set({
            token: null,
            customer: null,
            isInvalid: false,
            isLoading: false,
          });

          localStorage.removeItem("isContactLessModalOpened");
          get().authNavigate("/login");
        } catch (error) {
          console.error("Logout failed:", error);
          set({ isLoading: false });
          get().authNavigate("/login");
        }
      },

      getCustomer: async (customerId) => {
        const isPublicPath = PUBLIC_PATHS.some((path) =>
          window.location.pathname.startsWith(path),
        );

        try {
          set({ isLoading: !isPublicPath });
          const customerData = await getCustomerInfoServices(customerId);
          set({ isLoading: false });
          return customerData;
        } catch (error) {
          console.error("Error fetching customer:", error);
          set({ isLoading: false });
          throw error;
        }
      },

      // Payment actions
      setInvoiceData: (data) => set({ invoiceData: data }),
      setSignatureData: (data) => set({ signatureData: data }),
      setAmountArray: (arr) => set({ amountArray: arr }),

      // Communication actions
      setIsOtpSent: (status) => set({ isOtpSent: status }),
      setContactlessRequestDetails: (details) =>
        set({ contactlessRequestDetails: details }),
      setNotifications: (notifs) => set({ notifications: notifs }),
      setFundRequests: (requests) => set({ fundRequests: requests }),
    }),
    {
      name: "auth-store",
      partialize: (state) => ({
        token: state.token,
        customer: state.customer,
      }),
    },
  ),
);

export default useCustomerStore;
