import React, {Suspense, useEffect} from "react";
import {Navigate, Route, Routes, useLocation} from "react-router-dom";
import {isMobile, isTablet} from "react-device-detect";
import Landing from "../pages/landing";
import ProtectedRoute from "./ProtectedRoute";
import Loader from "../components/Loader";
import useCustomerStore from "../stores/useRefreshStore";
import ErrorBoundary from "../components/ErrorBoundary";

// Lazy loaded components
const Splash = React.lazy(() => import("../pages/splash"));
const Login = React.lazy(() => import("../pages/login"));
const Scan = React.lazy(() => import("../pages/scan"));
const PrivacyPolicy = React.lazy(() => import("../pages/privacyPolicy"));
const Contact = React.lazy(() => import("../pages/contact"));
const ContactNew = React.lazy(() => import("../pages/contactNew"));
const TermsConditions = React.lazy(() => import("../pages/termsConditions"));
const LicenseScanner = React.lazy(() => import("../pages/licenseScanner"));
const PassportScanner = React.lazy(() => import("../pages/passportScanner"));
const Phone = React.lazy(() => import("../pages/phone"));
const DashboardTransation = React.lazy(() =>
  import("../pages/dashboardTransation"),
);
const Dashboard = React.lazy(() => import("../pages/dashboard"));
const QrCodePage = React.lazy(() => import("../pages/qrCode"));
const QRCodeExample = React.lazy(() => import("../pages/QRCodeExample"));
const TwoFactor = React.lazy(() => import("../pages/two-factor"));
const Confirmation = React.lazy(() => import("../pages/confirmation"));
const TwoFactorLogin = React.lazy(() => import("../pages/2fa-pin"));
const WithdrawalRequestList = React.lazy(() =>
  import("../pages/withdrawalRequest"),
);
const BankList = React.lazy(() => import("../pages/bankList"));
const VerifyBank = React.lazy(() => import("../pages/verifyBank"));
const GoodsServices = React.lazy(() => import("../pages/goods-services"));
const FriendsAndFamily = React.lazy(() => import("../pages/friendsAndFamily"));
const LoadFund = React.lazy(() => import("../pages/load-fund"));
const Activity = React.lazy(() => import("../pages/activity"));
const FundRequests = React.lazy(() => import("../pages/fundRequests"));
const CompleteTransfer = React.lazy(() => import("../pages/completeTransfer"));
const AddFundingMethod = React.lazy(() => import("../pages/AddFundingMethod"));
const Request = React.lazy(() => import("../pages/request"));
const Payment = React.lazy(() => import("../pages/payment"));
const OnlyMobile = React.lazy(() => import("../components/OnlyMobile"));
const Invoice = React.lazy(() => import("../components/MainReceipt"));
const ESign = React.lazy(() => import("../pages/eSign"));

// Create a wrapper component for Suspense with ErrorBoundary
const LazyLoad = ({children}) => {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader/>}>
        {children}
      </Suspense>
    </ErrorBoundary>
  );
};

function AppRouter() {
  const {customer} = useCustomerStore();
  const location = useLocation();

  // Force customer state refresh on navigation
  useEffect(() => {
    const refreshStore = async () => {
      try {
        // This will trigger a re-fetch of customer data if needed
        await useCustomerStore.getState().refreshCustomer?.();
      } catch (error) {
        console.error("Failed to refresh customer data:", error);
      }
    };

    refreshStore();
  }, [location.pathname]);

  // Create conditional route sets for better organization
  const commonRoutes = (
    <>
      <Route path={"/"} element={<Landing/>}/>
      <Route path="/splash" element={<LazyLoad><Splash/></LazyLoad>}/>
      <Route path={"/receipt/:memoid?"} element={<LazyLoad><Invoice/></LazyLoad>}/>
      <Route path="/privacy-policy" element={<LazyLoad><PrivacyPolicy/></LazyLoad>}/>
      <Route path="/contact" element={<LazyLoad><ContactNew/></LazyLoad>}/>
      <Route path="/contact-new" element={<LazyLoad><Contact/></LazyLoad>}/>
      <Route path="/terms-conditions" element={<LazyLoad><TermsConditions/></LazyLoad>}/>
      <Route path={"/e-sign"} element={<LazyLoad><ESign/></LazyLoad>}/>
    </>
  );

  const mobileRoutes = isMobile && !isTablet && (
    <>
      <Route path="/login" element={<LazyLoad><Login/></LazyLoad>}/>
      <Route path="/scan/:contactlessId?" element={<LazyLoad><Scan p2p={false}/></LazyLoad>}/>
      <Route path={"/p2p/:p2pId?"} element={<LazyLoad><Scan p2p={true}/></LazyLoad>}/>
      <Route path={"/p2p-qr/:customerExtId"} element={<LazyLoad><Scan/></LazyLoad>}/>
      <Route path={"qrpay/:storeExternalId"} element={<LazyLoad><Scan p2p={false} qr={true}/></LazyLoad>}/>
      <Route path={"qr-license-scanner/:storeExternalId"}
             element={<LazyLoad><LicenseScanner p2p={false} qr={true}/></LazyLoad>}/>
      <Route path={"/license-scanner/:contactlessId?"} element={<LazyLoad><LicenseScanner p2p={false}/></LazyLoad>}/>
      <Route path={"/p2p-license-scanner/:p2pId?"} element={<LazyLoad><LicenseScanner p2p={true}/></LazyLoad>}/>
      <Route path={"/p2p-qr-license-scanner/:customerExtId"} element={<LazyLoad><LicenseScanner/></LazyLoad>}/>
      <Route path={"/p2p-qr-passport-scanner/:customerExtId"} element={<LazyLoad><PassportScanner/></LazyLoad>}/>
      <Route path={"/passport-scanner/:contactlessId?"} element={<LazyLoad><PassportScanner/></LazyLoad>}/>
      <Route path={"/p2p-passport-scanner/:p2pId?"} element={<LazyLoad><PassportScanner/></LazyLoad>}/>
      <Route path={"/qr-passport-scanner/:storeExternalId?"} element={<LazyLoad><PassportScanner/></LazyLoad>}/>
      <Route path={"/verify-phone/:custExtId?"} element={<LazyLoad><Phone/></LazyLoad>}/>

      {/* Protected routes */}
      <Route element={<ProtectedRoute/>}>
        <Route path={"/account-details"} element={<LazyLoad><DashboardTransation/></LazyLoad>}/>
        <Route path={"/dashboard/:p2pId?"} element={<LazyLoad><Dashboard/></LazyLoad>}/>
        <Route path={"/qrCode"} element={<LazyLoad><QrCodePage/></LazyLoad>}/>
        <Route path={"/qr"} element={<LazyLoad><QRCodeExample/></LazyLoad>}/>
        <Route path={"/two-factor"} element={<LazyLoad><TwoFactor/></LazyLoad>}/>
        <Route path={"/confirmation"} element={<LazyLoad><Confirmation/></LazyLoad>}/>
        <Route path={"/pin-login/:type?"} element={<LazyLoad><TwoFactorLogin/></LazyLoad>}/>
        <Route path={"/withdrawal-list"} element={<LazyLoad><WithdrawalRequestList customer={customer}/></LazyLoad>}/>
        <Route path={"/withdrawal"} element={<LazyLoad><BankList/></LazyLoad>}/>
        <Route path={"/verify-bank"} element={<LazyLoad><VerifyBank/></LazyLoad>}/>
        <Route path={"/goods-services/:externalId?/:custIdForDoobie?"} element={<LazyLoad><GoodsServices/></LazyLoad>}/>
        <Route path={"/goods-services-qr/:storeExternalId"} element={<LazyLoad><GoodsServices/></LazyLoad>}/>
        <Route path={"/transfer-money/:customerId?"} element={<LazyLoad><FriendsAndFamily/></LazyLoad>}/>
        <Route path={"/payment"} element={<LazyLoad><Payment/></LazyLoad>}/>
        <Route path={"/deposit-funds"} element={<LazyLoad><LoadFund/></LazyLoad>}/>
        <Route path={"/activity"} element={<LazyLoad><Activity/></LazyLoad>}/>
        <Route path={"/requests"} element={<LazyLoad><Request/></LazyLoad>}/>
        <Route path={"/fund-request/:extId"} element={<LazyLoad><FundRequests/></LazyLoad>}/>
        <Route path={"/complete-transfer"} element={<LazyLoad><CompleteTransfer/></LazyLoad>}/>
        <Route path={"/add-funding-method"} element={<LazyLoad><AddFundingMethod/></LazyLoad>}/>
      </Route>

      {/* Default redirect route - properly handling home redirects */}
      <Route path="/home" element={
        <ErrorBoundary>
          <Navigate to={customer ? "/dashboard" : "/login"} replace/>
        </ErrorBoundary>
      }/>
    </>
  );

  return (
    <ErrorBoundary>
      <Routes>
        {commonRoutes}
        {mobileRoutes}

        {/* Catch-all route */}
        <Route path={"*"} element={
          <LazyLoad>
            {isMobile && !isTablet ?
              <Navigate to={customer ? "/dashboard" : "/login"} replace/> :
              <OnlyMobile/>
            }
          </LazyLoad>
        }/>
      </Routes>
    </ErrorBoundary>
  );
}

export default AppRouter;