import React from "react";
import "../scss/landing.style.scss";
import { Box, Divider, } from "@mui/material";
import LeftBg from "../components/landing/leftBg";
import RightBg from "../components/landing/rightBg";
import HeaderLanding from "../components/landing/headerLanding";
import BannerLanding from "../components/landing/banner";
import HowItWork from "../components/landing/howItWork";
import FooterLanding from "../components/footer/footerLanding";
import SendingMoney from "../components/landing/sendingMoney";
import TrustSection from "../components/landing/trustSection";
import FinanceModule from "../components/landing/finance";
import AboutLanding from "../components/landing/aboutLanding";
import FormLanding from "../components/landing/formLanding";

function Landing() {
  
  return (
    <>
      <LeftBg />
      <RightBg />
      <Box component="div" className="landingWrapper">
        <HeaderLanding />
        <BannerLanding />
        <HowItWork />
        <SendingMoney />
        <Divider />
        <TrustSection />
        <FinanceModule/>
        <AboutLanding/>
        <FormLanding/>
        <FooterLanding />
      </Box>
    </>
  );
}

export default Landing;
