import React, { useEffect, useState } from "react";
import Splash from "../pages/splash";
import useCustomerStore from "../stores/useRefreshStore";

const AuthWrapper = ({ children }) => {
  const [isInitializing, setIsInitializing] = useState(true);
  const { isLoading, isInitialized, initialize } = useCustomerStore();

  useEffect(() => {
    const init = async () => {
      await initialize();
      setIsInitializing(false);
    };

    if (!isInitialized) {
      init();
    } else {
      setIsInitializing(false);
    }
  }, [initialize, isInitialized]);

  // Show splash screen during initial load
  if (isInitializing || isLoading) {
    return <Splash />;
  }

  return children;
};

export default AuthWrapper;
