import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const useWindowWidth = () => {
  const [isClient, setIsClient] = useState(false);
  const location = useLocation();

  const [windowWidth, setWindowWidth] = useState(
    typeof window !== "undefined" ? window.innerWidth : 0
  );
  const [isMobile, setIsMobile] = useState(false);
  const [isTab, setIsTab] = useState(false);
  const [isTabP, setIsTabP] = useState(false);

  useEffect(() => {
    setIsMobile(windowWidth < 600);
  }, [windowWidth, location.pathname, isClient]);

  useEffect(() => {
    setIsTabP(windowWidth < 900);
  }, [windowWidth, location.pathname, isClient]);

  useEffect(() => {
    setIsTab(windowWidth < 1080);
  }, [windowWidth, location.pathname, isClient]);

  useEffect(() => {
    const handleResize = () => {
      if (window) {
        setWindowWidth(window.innerWidth);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isClient, location.pathname]);

  useEffect(() => {
    setIsClient(true);
  }, []);

  return {
    windowWidth,
    isMobile,
    isTabP,
    isTab,
  };
};

export default useWindowWidth
