import React, { useEffect } from "react";
import { Container, Fab, styled, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const AboutWrapper = styled("section")(() => ({
  width: "100%",
  paddingBlockEnd: "2.5rem",
  // display: "flex",
  "@media (max-width: 550px)": {
    paddingInline: "1.25rem",
    paddingBlockEnd: "2.5rem",
  },
}));
const AboutStyle = styled("div")(() => ({
  width: "70%",
  borderRadius: "1.875rem",
  // background: "linear-gradient(338deg, #001640 -8.31%, #003D67 110.18%)",
  padding: "0",
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "@media (max-width: 550px)": {
    width: "100%",
  },
}));
// const AboutFormBtn = styled("div")(() => ({
//   color:'#fff',display:'flex',alignItems:'center',justifyContent:'center',fontSize:'0.9375rem',fontWeight:500,borderRadius:'0.25rem',bottom:'2.25rem',pointerEvents:'none',
//   position:'absolute',width:'6.25rem',height:'2.5rem',background:'linear-gradient(346deg, rgb(0, 22, 64) -8.91%, rgb(0, 152, 255) 136.27%)',
// }));
// const AboutFormBtn = styled("div")(() => ({ position:'absolute',width:'6.25rem',height:'2.5rem',background:'linear-gradient(346deg, rgb(0, 22, 64) -8.91%, rgb(0, 152, 255) 136.27%)',}),

const AboutContent = styled("div")(() => ({ position: "relative", zIndex: 2 }));
const AboutText = styled("div")(() => ({
  width: "100%",
  maxWidth: "60rem",
  padding: "2.5rem  6.25rem 2.5rem  0",
  marginInline: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "self-start",
  alignContent: "self-start",
  textAlign: "left",
  gap: "1.25rem",
  "@media (max-width: 768px)": {
    padding: "2.5rem 6.25rem",
  },
  "@media (max-width: 550px)": {
    padding: " 1.25rem 0",
  },
}));
const btnStyle = {
  // background: "linear-gradient(271deg, #FFF 15.14%, #0B99FF 137.79%)",
  background: "linear-gradient(346deg, #001640 -8.91%, #0098FF 136.27%)",

  color: "#fff",
  paddingInline: "2.5rem",
  fontSize: "1.25rem",
  fontWeight: 400,
};
export default function AboutLanding() {
  const router = useNavigate();
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//js.hsforms.net/forms/shell.js";
    script.async = true;
    script.onload = () => {
      setTimeout(() => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            region: "na1",
            portalId: "20470162",
            formId: "41b820a9-b129-40fe-9ce3-3f92e5f5d611",
            target: "#hubspotForm",
          });
        }
      }, 2000); // 1-second timeout
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <>
      <AboutWrapper>
        <Container
          maxWidth="xl"
          sx={{ display: "flex", gap: "1.25rem", flexWrap: "wrap" }}
        >
          <AboutStyle>
            <AboutContent>
              <AboutText>
                <motion.div
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 1, ease: "easeInOut", delay: 1.3 }}
                >
                  <Typography
                    variant="body1"
                    opacity={0.7}
                    my={0}
                  >{`A universal system that is as easy as texting without the need to download apps or register. Gain Funds removes friction and makes it easy to transact with anyone, anytime, and anywhere.`}</Typography>
                </motion.div>
                <motion.div
                  // whileHover={{ scale: 1.1, color: '#0F9AD6' }}
                  // transition={{ duration: 0.3 }}
                  initial={{ y: 30, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 1, ease: "easeInOut", delay: 1.75 }}
                >
                  <motion.div
                    whileHover={{ scale: 1.1, color: "#0F9AD6" }}
                    transition={{ duration: 0.3 }}
                  >
                    <Fab
                      variant="extended"
                      style={btnStyle}
                      sx={{ fontSize: { xs: "1rem!important", lg: "" } }}
                      onClick={() => router("/login")}
                      color="primary"
                    >
                      Get Started in seconds
                    </Fab>
                  </motion.div>
                </motion.div>
              </AboutText>
            </AboutContent>
          </AboutStyle>
        </Container>
      </AboutWrapper>
    </>
  );
}
