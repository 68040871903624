import React from "react";
export default function FooterLanding() {

  return (
    <>
      <section>
        
        <footer className="footerModule text-center py-3">
          <div className="">
            <span className={`text-gray-400`}>Powered by </span>
            <a
              href="https://gainfcu.com/"
              target={`_blank`}
              className="no-underline cursor-pointer"
            >
              Gain Federal Credit Union
            </a>
          </div>
        </footer>
      </section>
    </>
  );
}
