import React, { useEffect } from "react";
import { Link } from "react-router-dom";
// import GainFooterLogo from "../../svg/GainFooterLogo";
import EmailIcon from "../../svg/emailIcon";
import CallIcon from "../../svg/callIcon";
import {
  Box,
  Container,
  Divider,
  Grid,
  styled,
  SvgIcon,
  Typography,
} from "@mui/material";
import useWindowWidth from "../../utils/useWindowWidth";
import GainLogo from "../../svg/Gainlogo";

const FooterWrapper = styled("section")(() => ({
  width: "100%",
  paddingBlock: "5rem",
  background: "linear-gradient(338deg, #001640 -8.31%, #003D67 110.18%)",
  "@media (max-width: 550px)": {
    paddingBlock: "2.5rem",
    paddingInline: "1.25rem",
  },
  "& .brand": {
    maxWidth: "12.5rem",
    marginBlockEnd: "1.25rem",
    display: "flex",
  },
  // '& .glc1 , &.glc2': {fill: 'white',}
}));

const LandingForm = styled("div")(() => ({
  width: "100%",
  borderRadius: "1.875rem",
  background: "linear-gradient(338deg, #edf3ff -8.31%, #fbfbff 110.18%)",
  padding: "1.25rem",
  minHeight: "34.25rem!important",
  position: "absolute",
  bottom: 0,
  right: 0,
  "@media (max-width: 550px)": {
    width: "100%",
    position: "static",
  },
}));
// const LandingFormBtn = styled("div")(() => ({
//   color:'#fff',display:'flex',alignItems:'center',justifyContent:'center',fontSize:'0.9375rem',fontWeight:500,borderRadius:'0.25rem',bottom:'2.25rem',pointerEvents:'none',
//   position:'absolute',width:'6.25rem',height:'2.5rem',background:'linear-gradient(346deg, rgb(0, 22, 64) -8.91%, rgb(0, 152, 255) 136.27%)',
// }));
// const LandingFormBtn = styled("div")(() => ({ position:'absolute',width:'6.25rem',height:'2.5rem',background:'linear-gradient(346deg, rgb(0, 22, 64) -8.91%, rgb(0, 152, 255) 136.27%)',}),

export default function FormLanding() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//js.hsforms.net/forms/shell.js";
    script.async = true;
    script.onload = () => {
      setTimeout(() => {
        if (window.hbspt) {
          window.hbspt.forms.create({
            region: "na1",
            portalId: "20470162",
            formId: "41b820a9-b129-40fe-9ce3-3f92e5f5d611",
            target: "#hubspotForm",
          });
        }
      }, 2000); // 1-second timeout
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  const { isMobile } = useWindowWidth();
  return (
    <>
      <FooterWrapper>
        <Container maxWidth="xl">
          <Grid
            container
            spacing={2}
            justifyContent={{ xs: "center", lg: "space-between" }}
            alignItems={"center"}
          >
            <Grid
              item
              xs={12}
              lg={4}
              xl={3.5}
              order={{ xs: 0, lg: 2 }}
              position={"relative"}
              alignSelf={"stretch"}
            >
              <LandingForm>
                <div id="hubspotForm"></div>
                {/* <div>asd</div> */}
                {/* <LandingFormBtn>Submit</LandingFormBtn> */}
              </LandingForm>
            </Grid>
            <Grid item xs={12} lg={5} xl={4}>
              <Link underline="none" to={`/`} className="dark brand">
                <GainLogo />
              </Link>
              <Typography variant="body2" color={"#fff"} sx={{ opacity: 0.6 }}>
                Gain Funds is built to transform the banking and financial
                experience. Gain Funds leverages the latest innovations in
                technology to provide a safe, convenient, and trusted account
                for customers to access with a government-issued ID.
              </Typography>
              <>
                <Box
                  display={"inline-flex"}
                  gap={"1.25rem"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  width={{ xs: "100%", lg: "auto" }}
                  mt={2.5}
                  order={{ xs: 1, lg: 0 }}
                >
                  <Typography
                    variant=""
                    display={"flex"}
                    alignItems={"center"}
                    gap={1.125}
                    fontSize={{ xs: "0.75rem", lg: "" }}
                    color={"#fff"}
                  >
                    <SvgIcon viewBox="0 0 17 15" sx={{ width: 16, height: 16 }}>
                      <EmailIcon />
                    </SvgIcon>
                    support@gainfunds.com
                  </Typography>
                  <Typography
                    variant=""
                    display={"flex"}
                    alignItems={"center"}
                    gap={1.125}
                    fontSize={{ xs: "0.75rem", lg: "" }}
                    color={"#fff"}
                  >
                    <SvgIcon viewBox="0 0 17 15" sx={{ width: 16, height: 16 }}>
                      <CallIcon />
                    </SvgIcon>
                    {`(855) 732-7272`}
                  </Typography>
                </Box>
              </>
            </Grid>
            <Grid
              container
              gap={2}
              item
              xs={12}
              lg={2}
              color={"#fff"}
              fontSize={{ xs: "0.875rem", lg: "1.25rem" }}
            >
              {isMobile && (
                <>
                  <Divider color={"#fff"} sx={{ my: 2, width: "100%" }} />
                </>
              )}
              <Grid item xs={6} lg={12}>
                <Link underline="hover" to="/contact">
                  Contact Us
                </Link>
              </Grid>
              <Grid item xs={6} lg={12}>
                <Link underline="hover" to="/terms-conditions">
                  Terms & Conditions
                </Link>
              </Grid>
              <Grid item xs={6} lg={12}>
                <Link underline="hover" to="/privacy-policy">
                  Privacy Policy
                </Link>
              </Grid>
              <Grid item xs={6} lg={12}>
                <Link underline="hover" to="/e-sign">
                  E-sign Disclosure
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </FooterWrapper>
    </>
  );
}
