import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import useCustomerStore from "../stores/useRefreshStore";
import Loader from "../components/Loader"; // Import your loader component

const ProtectedRoute = () => {
  const location = useLocation();
  const {
    customer,
    isInvalid,
    isInitialized,
    isLoading,
    getCustomer,
    token,
    setCustomer,
  } = useCustomerStore();

  useEffect(() => {
    const checkInitialization = async () => {
      // Only initialize if we have a token but aren't initialized
      if (!customer?.amount) {
        console.log("Initializing protected route...");
        const customerData = await getCustomer(customer?.customer_external_id);
        setCustomer(customerData);
      }
    };
    checkInitialization();
  }, [token, isInitialized, getCustomer]);

  // Show loader while initializing or loading
  if (isLoading || (token && !isInitialized)) {
    return <Loader show={true} />;
  }

  // Redirect to login if no auth
  if (isInvalid || !customer || !token) {
    console.log("No auth, redirecting to login");
    sessionStorage.setItem("redirectPath", location.pathname);
    // return <Navigate to="/login" replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
