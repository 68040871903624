import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null
    };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render shows the fallback UI
    return {hasError: true, error};
  }

  componentDidCatch(error, errorInfo) {
    // You can log the error to an error reporting service
    console.error("Error caught by ErrorBoundary:", error, errorInfo);
    this.setState({errorInfo});
  }

  handleReset = () => {
    this.setState({hasError: false, error: null, errorInfo: null});
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-boundary-container" style={{
          padding: "20px",
          margin: "20px",
          borderRadius: "8px",
          backgroundColor: "#fff1f0",
          border: "1px solid #ffa39e",
          textAlign: "center"
        }}>
          <h2 style={{color: "#cf1322"}}>Something went wrong</h2>
          <p>{`We're sorry, but an error occurred in the application.`}</p>
          {this.state.error && (
            <details style={{
              marginTop: "15px",
              padding: "10px",
              backgroundColor: "#fff",
              border: "1px solid #d9d9d9",
              borderRadius: "4px",
              textAlign: "left"
            }}>
              <summary style={{fontWeight: "bold", cursor: "pointer"}}>Error Details</summary>
              <pre style={{
                padding: "10px",
                overflow: "auto",
                backgroundColor: "#f5f5f5",
                borderRadius: "4px",
                marginTop: "10px"
              }}>
                {this.state.error.toString()}
                {this.state.errorInfo && this.state.errorInfo.componentStack}
              </pre>
            </details>
          )}
          <div style={{marginTop: "20px"}}>
            <button
              onClick={() => window.location.reload()}
              style={{
                padding: "8px 16px",
                backgroundColor: "#1890ff",
                color: "white",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
                marginRight: "10px"
              }}
            >
              Refresh Page
            </button>
            <button
              onClick={this.handleReset}
              style={{
                padding: "8px 16px",
                backgroundColor: "white",
                color: "#1890ff",
                border: "1px solid #1890ff",
                borderRadius: "4px",
                cursor: "pointer"
              }}
            >
              Try Again
            </button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;