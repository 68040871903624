import React from "react";
import { Box, Container, Grid, Typography, styled } from "@mui/material";
import LandingWave from "./landingWave";
import TransferScreen from "../../Assets/image/transfer-screen.png"
import ShareScreen from "../../Assets/image/share-screen.png"
import ShareScreenMobile from "../../Assets/image/share-screen-mobile.png"
import video from "../../Assets/share-video.mp4";
import Image from "../../Assets/image/sending-money-img.png";

import useWindowWidth from "../../utils/useWindowWidth";
const ModuleWrapper = styled("section")(() => ({
  width: '100%', paddingBlock: '3.75rem', backgroundColor: '#fff', position: 'relative',overflow:'hidden',
  '&:before': { content: `''`, width: '100%', height: '100%', position: 'absolute', left: 0, top: 0, background: ' linear-gradient(to bottom, rgba(0,17,28,1) 30%,rgba(255,255,255,0.02) 89%,rgba(255,255,255,0) 90%)', },
  '@media (max-width: 550px)': {
    paddingBlock:'2.5rem 1.25rem',paddingInline:'1.25rem',
  },
}));

const LeftWave = styled("div")(() => ({ width: '32.5rem', height: '10rem', position: 'absolute', left: '0', bottom: '15%',transform:'rotate(-25deg)',
'@media (max-width: 550px)': {
  width: '12.5rem',bottom:'-5%',left:'-10%',
},
}));
const RightWave = styled("div")(() => ({ width: '32.5rem', height: '10rem', position: 'absolute', right: '15%', top: '30%', 
  '@media (max-width: 550px)': {
    width: '12.5rem',top:'5%',transform:'rotate(-10deg)',right:'-10%',
  },
}));
const ScreenBox = styled("div")(() => ({
  width: '18.75rem', height: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff', borderRadius: '1.25rem',
  '& img': { width: '100%', height: 'auto' }
}));
const ShareBox = styled("div")(() => ({
  width: '100%', height: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'center', position:'relative', 
  '& img': { width: '100%', height: 'auto' },
  '@media (max-width: 550px)': {
    width: '90%',
  },
}));
const VideoWrapper = styled("div")(() => ({width: '25rem', position:'relative',zIndex:2,
  '@media (max-width: 550px)': {
    position:'absolute',width:'40%',right:0,top:'1.875rem',marginBlockStart:'0rem',
  },
}));
const VideoBox = styled("div")(() => ({height:'40.525rem',borderRadius:'15rem',overflow:'hidden', position:'relative',border:'#fff solid 0.625rem',
  '& video, & img': { width: '100%', height: '100%',objectFit:"cover"},
  '@media (max-width: 550px)': {
    height:'13.75rem',borderRadius:'15rem',border:'#fff solid 0.125rem',
  },
}));
const titelStyle = { background: 'linear-gradient(to right, #0B99FF -30%, #fff 100%)', backgroundClip: 'text', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', };
const ModuleContainer = { position: 'relative', }
export default function SendingMoney() {
  const { isMobile } = useWindowWidth();
  return (
    <>
      <ModuleWrapper>
        <Container maxWidth="xl" style={ModuleContainer}>

          <Box textAlign={'center'}>

            <Typography variant="h2" className="titel" style={titelStyle} mb={0}>
              {/* sending money is now {isMobile && (<><br /></>)}as easy {!isMobile && (<><br /></>)}as texting */}
              Funds Transfers … Start with a Text
            </Typography>
          </Box>
          <LeftWave>
            <LandingWave />
          </LeftWave>
          <RightWave>
            <LandingWave />
          </RightWave>
          <Grid container py={{xs:2,lg:5}}  position={'relative'}  alignItems={'center'}> 
          {isMobile ?(
              <>
                <Grid item xs={12}>
                  <ShareBox>
                    <img src={ShareScreenMobile} alt="" />
                  </ShareBox>
                </Grid>
              </>
            ):(
              <>
                <Grid item lg={2.5} xl={3}>
                <ScreenBox>
                  <img src={TransferScreen} alt="" />
                </ScreenBox>
              </Grid>
              <Grid item lg={5} xl={5.5} alignSelf={'flex-end'}>
                <ShareBox>
                  <img src={ShareScreen} alt="" />
                </ShareBox>
              </Grid>
                
              </>
            )}
            <Grid item lg={4.5} xl={3.5}>
              <VideoWrapper>
                <VideoBox>
                  {!isMobile ? (
                    <video
                      controls={false}
                      autoPlay
                      muted
                      loop
                    >
                      <source src={video} type="video/mp4" />
                    </video>
                  ):(
                    <img src={Image} alt="Image"/>
                  )}
                </VideoBox>
                
              </VideoWrapper>
            </Grid>
            
          </Grid>
        </Container>

      </ModuleWrapper>
    </>
  );
}
