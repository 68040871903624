import React from "react";
export default function CircleBox() {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="117" height="103" viewBox="0 0 117 103" fill="none">
                <circle cx="1.89328" cy="1.89401" r="1.89401" fill="#D9D9D9"/>
                <circle cx="1.89328" cy="8.52316" r="1.89401" fill="#D9D9D9"/>
                <circle cx="1.89328" cy="15.1521" r="1.89401" fill="#D9D9D9"/>
                <circle cx="1.89328" cy="21.7812" r="1.89401" fill="#D9D9D9"/>
                <circle cx="1.89328" cy="28.4101" r="1.89401" fill="#D9D9D9"/>
                <circle cx="1.89328" cy="35.039" r="1.89401" fill="#D9D9D9"/>
                <circle cx="1.89328" cy="41.6682" r="1.89401" fill="#D9D9D9"/>
                <circle cx="1.89328" cy="48.2973" r="1.89401" fill="#D9D9D9"/>
                <circle cx="8.52218" cy="1.89401" r="1.89401" fill="#D9D9D9"/>
                <circle cx="8.52218" cy="8.52316" r="1.89401" fill="#D9D9D9"/>
                <circle cx="8.52218" cy="15.1521" r="1.89401" fill="#D9D9D9"/>
                <circle cx="8.52218" cy="21.7812" r="1.89401" fill="#D9D9D9"/>
                <circle cx="8.52218" cy="28.4101" r="1.89401" fill="#D9D9D9"/>
                <circle cx="8.52218" cy="35.039" r="1.89401" fill="#D9D9D9"/>
                <circle cx="8.52218" cy="41.6682" r="1.89401" fill="#D9D9D9"/>
                <circle cx="8.52218" cy="48.2973" r="1.89401" fill="#D9D9D9"/>
                <circle cx="15.1513" cy="1.89401" r="1.89401" fill="#D9D9D9"/>
                <circle cx="15.1513" cy="8.52316" r="1.89401" fill="#D9D9D9"/>
                <circle cx="15.1513" cy="15.1521" r="1.89401" fill="#D9D9D9"/>
                <circle cx="15.1513" cy="21.7812" r="1.89401" fill="#D9D9D9"/>
                <circle cx="15.1513" cy="28.4101" r="1.89401" fill="#D9D9D9"/>
                <circle cx="15.1513" cy="35.039" r="1.89401" fill="#D9D9D9"/>
                <circle cx="15.1513" cy="41.6682" r="1.89401" fill="#D9D9D9"/>
                <circle cx="15.1513" cy="48.2973" r="1.89401" fill="#D9D9D9"/>
                <circle cx="21.7802" cy="1.89401" r="1.89401" fill="#D9D9D9"/>
                <circle cx="21.7802" cy="8.52316" r="1.89401" fill="#D9D9D9"/>
                <circle cx="21.7802" cy="15.1521" r="1.89401" fill="#D9D9D9"/>
                <circle cx="21.7802" cy="21.7812" r="1.89401" fill="#D9D9D9"/>
                <circle cx="21.7802" cy="28.4101" r="1.89401" fill="#D9D9D9"/>
                <circle cx="21.7802" cy="35.039" r="1.89401" fill="#D9D9D9"/>
                <circle cx="21.7802" cy="41.6682" r="1.89401" fill="#D9D9D9"/>
                <circle cx="21.7802" cy="48.2973" r="1.89401" fill="#D9D9D9"/>
                <circle cx="28.4094" cy="1.89401" r="1.89401" fill="#D9D9D9"/>
                <circle cx="28.4094" cy="8.52316" r="1.89401" fill="#D9D9D9"/>
                <circle cx="28.4094" cy="15.1521" r="1.89401" fill="#D9D9D9"/>
                <circle cx="28.4094" cy="21.7812" r="1.89401" fill="#D9D9D9"/>
                <circle cx="28.4094" cy="28.4101" r="1.89401" fill="#D9D9D9"/>
                <circle cx="28.4094" cy="35.039" r="1.89401" fill="#D9D9D9"/>
                <circle cx="28.4094" cy="41.6682" r="1.89401" fill="#D9D9D9"/>
                <circle cx="28.4094" cy="48.2973" r="1.89401" fill="#D9D9D9"/>
                <circle cx="35.0383" cy="1.89401" r="1.89401" fill="#D9D9D9"/>
                <circle cx="35.0383" cy="8.52316" r="1.89401" fill="#D9D9D9"/>
                <circle cx="35.0383" cy="15.1521" r="1.89401" fill="#D9D9D9"/>
                <circle cx="35.0383" cy="21.7812" r="1.89401" fill="#D9D9D9"/>
                <circle cx="35.0383" cy="28.4101" r="1.89401" fill="#D9D9D9"/>
                <circle cx="35.0383" cy="35.039" r="1.89401" fill="#D9D9D9"/>
                <circle cx="35.0383" cy="41.6682" r="1.89401" fill="#D9D9D9"/>
                <circle cx="35.0383" cy="48.2973" r="1.89401" fill="#D9D9D9"/>
                <circle cx="41.6674" cy="1.89401" r="1.89401" fill="#D9D9D9"/>
                <circle cx="41.6674" cy="8.52316" r="1.89401" fill="#D9D9D9"/>
                <circle cx="41.6674" cy="15.1521" r="1.89401" fill="#D9D9D9"/>
                <circle cx="41.6674" cy="21.7812" r="1.89401" fill="#D9D9D9"/>
                <circle cx="41.6674" cy="28.4101" r="1.89401" fill="#D9D9D9"/>
                <circle cx="41.6674" cy="35.039" r="1.89401" fill="#D9D9D9"/>
                <circle cx="41.6674" cy="41.6682" r="1.89401" fill="#D9D9D9"/>
                <circle cx="41.6674" cy="48.2973" r="1.89401" fill="#D9D9D9"/>
                <circle cx="48.2966" cy="1.89401" r="1.89401" fill="#D9D9D9"/>
                <circle cx="54.9255" cy="1.89401" r="1.89401" fill="#D9D9D9"/>
                <circle cx="48.2966" cy="8.52316" r="1.89401" fill="#D9D9D9"/>
                <circle cx="54.9255" cy="8.52316" r="1.89401" fill="#D9D9D9"/>
                <circle cx="48.2966" cy="15.1521" r="1.89401" fill="#D9D9D9"/>
                <circle cx="54.9255" cy="15.1521" r="1.89401" fill="#D9D9D9"/>
                <circle cx="48.2966" cy="21.7812" r="1.89401" fill="#D9D9D9"/>
                <circle cx="54.9255" cy="21.7812" r="1.89401" fill="#D9D9D9"/>
                <circle cx="48.2966" cy="28.4101" r="1.89401" fill="#D9D9D9"/>
                <circle cx="54.9255" cy="28.4101" r="1.89401" fill="#D9D9D9"/>
                <circle cx="48.2966" cy="35.039" r="1.89401" fill="#D9D9D9"/>
                <circle cx="54.9255" cy="35.039" r="1.89401" fill="#D9D9D9"/>
                <circle cx="48.2966" cy="41.6682" r="1.89401" fill="#D9D9D9"/>
                <circle cx="48.2966" cy="48.2973" r="1.89401" fill="#D9D9D9"/>
                <circle cx="54.9255" cy="41.6682" r="1.89401" fill="#D9D9D9"/>
                <circle cx="54.9255" cy="48.2973" r="1.89401" fill="#D9D9D9"/>
                <circle cx="1.89328" cy="54.4528" r="1.89401" fill="#D9D9D9"/>
                <circle cx="1.89328" cy="61.0818" r="1.89401" fill="#D9D9D9"/>
                <circle cx="1.89328" cy="67.7109" r="1.89401" fill="#D9D9D9"/>
                <circle cx="1.89328" cy="74.3401" r="1.89401" fill="#D9D9D9"/>
                <circle cx="1.89328" cy="80.9687" r="1.89401" fill="#D9D9D9"/>
                <circle cx="1.89328" cy="87.5979" r="1.89401" fill="#D9D9D9"/>
                <circle cx="1.89328" cy="94.227" r="1.89401" fill="#D9D9D9"/>
                <circle cx="1.89328" cy="100.856" r="1.89401" fill="#D9D9D9"/>
                <circle cx="8.52218" cy="54.4528" r="1.89401" fill="#D9D9D9"/>
                <circle cx="8.52218" cy="61.0818" r="1.89401" fill="#D9D9D9"/>
                <circle cx="8.52218" cy="67.7109" r="1.89401" fill="#D9D9D9"/>
                <circle cx="8.52218" cy="74.3401" r="1.89401" fill="#D9D9D9"/>
                <circle cx="8.52218" cy="80.9687" r="1.89401" fill="#D9D9D9"/>
                <circle cx="8.52218" cy="87.5979" r="1.89401" fill="#D9D9D9"/>
                <circle cx="8.52218" cy="94.227" r="1.89401" fill="#D9D9D9"/>
                <circle cx="8.52218" cy="100.856" r="1.89401" fill="#D9D9D9"/>
                <circle cx="15.1513" cy="54.4528" r="1.89401" fill="#D9D9D9"/>
                <circle cx="15.1513" cy="61.0818" r="1.89401" fill="#D9D9D9"/>
                <circle cx="15.1513" cy="67.7109" r="1.89401" fill="#D9D9D9"/>
                <circle cx="15.1513" cy="74.3401" r="1.89401" fill="#D9D9D9"/>
                <circle cx="15.1513" cy="80.9687" r="1.89401" fill="#D9D9D9"/>
                <circle cx="15.1513" cy="87.5979" r="1.89401" fill="#D9D9D9"/>
                <circle cx="15.1513" cy="94.227" r="1.89401" fill="#D9D9D9"/>
                <circle cx="15.1513" cy="100.856" r="1.89401" fill="#D9D9D9"/>
                <circle cx="21.7802" cy="54.4528" r="1.89401" fill="#D9D9D9"/>
                <circle cx="21.7802" cy="61.0818" r="1.89401" fill="#D9D9D9"/>
                <circle cx="21.7802" cy="67.7109" r="1.89401" fill="#D9D9D9"/>
                <circle cx="21.7802" cy="74.3401" r="1.89401" fill="#D9D9D9"/>
                <circle cx="21.7802" cy="80.9687" r="1.89401" fill="#D9D9D9"/>
                <circle cx="21.7802" cy="87.5979" r="1.89401" fill="#D9D9D9"/>
                <circle cx="21.7802" cy="94.227" r="1.89401" fill="#D9D9D9"/>
                <circle cx="21.7802" cy="100.856" r="1.89401" fill="#D9D9D9"/>
                <circle cx="28.4094" cy="54.4528" r="1.89401" fill="#D9D9D9"/>
                <circle cx="28.4094" cy="61.0818" r="1.89401" fill="#D9D9D9"/>
                <circle cx="28.4094" cy="67.7109" r="1.89401" fill="#D9D9D9"/>
                <circle cx="28.4094" cy="74.3401" r="1.89401" fill="#D9D9D9"/>
                <circle cx="28.4094" cy="80.9687" r="1.89401" fill="#D9D9D9"/>
                <circle cx="28.4094" cy="87.5979" r="1.89401" fill="#D9D9D9"/>
                <circle cx="28.4094" cy="94.227" r="1.89401" fill="#D9D9D9"/>
                <circle cx="28.4094" cy="100.856" r="1.89401" fill="#D9D9D9"/>
                <circle cx="35.0383" cy="54.4528" r="1.89401" fill="#D9D9D9"/>
                <circle cx="35.0383" cy="61.0818" r="1.89401" fill="#D9D9D9"/>
                <circle cx="35.0383" cy="67.7109" r="1.89401" fill="#D9D9D9"/>
                <circle cx="35.0383" cy="74.3401" r="1.89401" fill="#D9D9D9"/>
                <circle cx="35.0383" cy="80.9687" r="1.89401" fill="#D9D9D9"/>
                <circle cx="35.0383" cy="87.5979" r="1.89401" fill="#D9D9D9"/>
                <circle cx="35.0383" cy="94.227" r="1.89401" fill="#D9D9D9"/>
                <circle cx="35.0383" cy="100.856" r="1.89401" fill="#D9D9D9"/>
                <circle cx="41.6674" cy="54.4528" r="1.89401" fill="#D9D9D9"/>
                <circle cx="41.6674" cy="61.0818" r="1.89401" fill="#D9D9D9"/>
                <circle cx="41.6674" cy="67.7109" r="1.89401" fill="#D9D9D9"/>
                <circle cx="41.6674" cy="74.3401" r="1.89401" fill="#D9D9D9"/>
                <circle cx="41.6674" cy="80.9687" r="1.89401" fill="#D9D9D9"/>
                <circle cx="41.6674" cy="87.5979" r="1.89401" fill="#D9D9D9"/>
                <circle cx="41.6674" cy="94.227" r="1.89401" fill="#D9D9D9"/>
                <circle cx="41.6674" cy="100.856" r="1.89401" fill="#D9D9D9"/>
                <circle cx="48.2966" cy="54.4528" r="1.89401" fill="#D9D9D9"/>
                <circle cx="54.9255" cy="54.4528" r="1.89401" fill="#D9D9D9"/>
                <circle cx="48.2966" cy="61.0818" r="1.89401" fill="#D9D9D9"/>
                <circle cx="54.9255" cy="61.0818" r="1.89401" fill="#D9D9D9"/>
                <circle cx="48.2966" cy="67.7109" r="1.89401" fill="#D9D9D9"/>
                <circle cx="54.9255" cy="67.7109" r="1.89401" fill="#D9D9D9"/>
                <circle cx="48.2966" cy="74.3401" r="1.89401" fill="#D9D9D9"/>
                <circle cx="54.9255" cy="74.3401" r="1.89401" fill="#D9D9D9"/>
                <circle cx="48.2966" cy="80.9687" r="1.89401" fill="#D9D9D9"/>
                <circle cx="54.9255" cy="80.9687" r="1.89401" fill="#D9D9D9"/>
                <circle cx="48.2966" cy="87.5979" r="1.89401" fill="#D9D9D9"/>
                <circle cx="54.9255" cy="87.5979" r="1.89401" fill="#D9D9D9"/>
                <circle cx="48.2966" cy="94.227" r="1.89401" fill="#D9D9D9"/>
                <circle cx="48.2966" cy="100.856" r="1.89401" fill="#D9D9D9"/>
                <circle cx="54.9255" cy="94.227" r="1.89401" fill="#D9D9D9"/>
                <circle cx="54.9255" cy="100.856" r="1.89401" fill="#D9D9D9"/>
                <circle cx="61.239" cy="1.89401" r="1.89401" fill="#D9D9D9"/>
                <circle cx="61.239" cy="8.52316" r="1.89401" fill="#D9D9D9"/>
                <circle cx="61.239" cy="15.1521" r="1.89401" fill="#D9D9D9"/>
                <circle cx="61.239" cy="21.7812" r="1.89401" fill="#D9D9D9"/>
                <circle cx="61.239" cy="28.4101" r="1.89401" fill="#D9D9D9"/>
                <circle cx="61.239" cy="35.039" r="1.89401" fill="#D9D9D9"/>
                <circle cx="61.239" cy="41.6682" r="1.89401" fill="#D9D9D9"/>
                <circle cx="61.239" cy="48.2973" r="1.89401" fill="#D9D9D9"/>
                <circle cx="67.8681" cy="1.89401" r="1.89401" fill="#D9D9D9"/>
                <circle cx="67.8681" cy="8.52316" r="1.89401" fill="#D9D9D9"/>
                <circle cx="67.8681" cy="15.1521" r="1.89401" fill="#D9D9D9"/>
                <circle cx="67.8681" cy="21.7812" r="1.89401" fill="#D9D9D9"/>
                <circle cx="67.8681" cy="28.4101" r="1.89401" fill="#D9D9D9"/>
                <circle cx="67.8681" cy="35.039" r="1.89401" fill="#D9D9D9"/>
                <circle cx="67.8681" cy="41.6682" r="1.89401" fill="#D9D9D9"/>
                <circle cx="67.8681" cy="48.2973" r="1.89401" fill="#D9D9D9"/>
                <circle cx="74.497" cy="1.89401" r="1.89401" fill="#D9D9D9"/>
                <circle cx="74.497" cy="8.52316" r="1.89401" fill="#D9D9D9"/>
                <circle cx="74.497" cy="15.1521" r="1.89401" fill="#D9D9D9"/>
                <circle cx="74.497" cy="21.7812" r="1.89401" fill="#D9D9D9"/>
                <circle cx="74.497" cy="28.4101" r="1.89401" fill="#D9D9D9"/>
                <circle cx="74.497" cy="35.039" r="1.89401" fill="#D9D9D9"/>
                <circle cx="74.497" cy="41.6682" r="1.89401" fill="#D9D9D9"/>
                <circle cx="74.497" cy="48.2973" r="1.89401" fill="#D9D9D9"/>
                <circle cx="81.1259" cy="1.89401" r="1.89401" fill="#D9D9D9"/>
                <circle cx="81.1259" cy="8.52316" r="1.89401" fill="#D9D9D9"/>
                <circle cx="81.1259" cy="15.1521" r="1.89401" fill="#D9D9D9"/>
                <circle cx="81.1259" cy="21.7812" r="1.89401" fill="#D9D9D9"/>
                <circle cx="81.1259" cy="28.4101" r="1.89401" fill="#D9D9D9"/>
                <circle cx="81.1259" cy="35.039" r="1.89401" fill="#D9D9D9"/>
                <circle cx="81.1259" cy="41.6682" r="1.89401" fill="#D9D9D9"/>
                <circle cx="81.1259" cy="48.2973" r="1.89401" fill="#D9D9D9"/>
                <circle cx="87.7551" cy="1.89401" r="1.89401" fill="#D9D9D9"/>
                <circle cx="87.7551" cy="8.52316" r="1.89401" fill="#D9D9D9"/>
                <circle cx="87.7551" cy="15.1521" r="1.89401" fill="#D9D9D9"/>
                <circle cx="87.7551" cy="21.7812" r="1.89401" fill="#D9D9D9"/>
                <circle cx="87.7551" cy="28.4101" r="1.89401" fill="#D9D9D9"/>
                <circle cx="87.7551" cy="35.039" r="1.89401" fill="#D9D9D9"/>
                <circle cx="87.7551" cy="41.6682" r="1.89401" fill="#D9D9D9"/>
                <circle cx="87.7551" cy="48.2973" r="1.89401" fill="#D9D9D9"/>
                <circle cx="94.3842" cy="1.89401" r="1.89401" fill="#D9D9D9"/>
                <circle cx="94.3842" cy="8.52316" r="1.89401" fill="#D9D9D9"/>
                <circle cx="94.3842" cy="15.1521" r="1.89401" fill="#D9D9D9"/>
                <circle cx="94.3842" cy="21.7812" r="1.89401" fill="#D9D9D9"/>
                <circle cx="94.3842" cy="28.4101" r="1.89401" fill="#D9D9D9"/>
                <circle cx="94.3842" cy="35.039" r="1.89401" fill="#D9D9D9"/>
                <circle cx="94.3842" cy="41.6682" r="1.89401" fill="#D9D9D9"/>
                <circle cx="94.3842" cy="48.2973" r="1.89401" fill="#D9D9D9"/>
                <circle cx="101.013" cy="1.89401" r="1.89401" fill="#D9D9D9"/>
                <circle cx="101.013" cy="8.52316" r="1.89401" fill="#D9D9D9"/>
                <circle cx="101.013" cy="15.1521" r="1.89401" fill="#D9D9D9"/>
                <circle cx="101.013" cy="21.7812" r="1.89401" fill="#D9D9D9"/>
                <circle cx="101.013" cy="28.4101" r="1.89401" fill="#D9D9D9"/>
                <circle cx="101.013" cy="35.039" r="1.89401" fill="#D9D9D9"/>
                <circle cx="101.013" cy="41.6682" r="1.89401" fill="#D9D9D9"/>
                <circle cx="101.013" cy="48.2973" r="1.89401" fill="#D9D9D9"/>
                <circle cx="107.642" cy="1.89401" r="1.89401" fill="#D9D9D9"/>
                <circle cx="114.271" cy="1.89401" r="1.89401" fill="#D9D9D9"/>
                <circle cx="107.642" cy="8.52316" r="1.89401" fill="#D9D9D9"/>
                <circle cx="114.271" cy="8.52316" r="1.89401" fill="#D9D9D9"/>
                <circle cx="107.642" cy="15.1521" r="1.89401" fill="#D9D9D9"/>
                <circle cx="114.271" cy="15.1521" r="1.89401" fill="#D9D9D9"/>
                <circle cx="107.642" cy="21.7812" r="1.89401" fill="#D9D9D9"/>
                <circle cx="114.271" cy="21.7812" r="1.89401" fill="#D9D9D9"/>
                <circle cx="107.642" cy="28.4101" r="1.89401" fill="#D9D9D9"/>
                <circle cx="114.271" cy="28.4101" r="1.89401" fill="#D9D9D9"/>
                <circle cx="107.642" cy="35.039" r="1.89401" fill="#D9D9D9"/>
                <circle cx="114.271" cy="35.039" r="1.89401" fill="#D9D9D9"/>
                <circle cx="107.642" cy="41.6682" r="1.89401" fill="#D9D9D9"/>
                <circle cx="107.642" cy="48.2973" r="1.89401" fill="#D9D9D9"/>
                <circle cx="114.271" cy="41.6682" r="1.89401" fill="#D9D9D9"/>
                <circle cx="114.271" cy="48.2973" r="1.89401" fill="#D9D9D9"/>
                <circle cx="61.239" cy="54.4528" r="1.89401" fill="#D9D9D9"/>
                <circle cx="61.239" cy="61.0818" r="1.89401" fill="#D9D9D9"/>
                <circle cx="61.239" cy="67.7109" r="1.89401" fill="#D9D9D9"/>
                <circle cx="61.239" cy="74.3401" r="1.89401" fill="#D9D9D9"/>
                <circle cx="61.239" cy="80.9687" r="1.89401" fill="#D9D9D9"/>
                <circle cx="61.239" cy="87.5979" r="1.89401" fill="#D9D9D9"/>
                <circle cx="61.239" cy="94.227" r="1.89401" fill="#D9D9D9"/>
                <circle cx="61.239" cy="100.856" r="1.89401" fill="#D9D9D9"/>
                <circle cx="67.8681" cy="54.4528" r="1.89401" fill="#D9D9D9"/>
                <circle cx="67.8681" cy="61.0818" r="1.89401" fill="#D9D9D9"/>
                <circle cx="67.8681" cy="67.7109" r="1.89401" fill="#D9D9D9"/>
                <circle cx="67.8681" cy="74.3401" r="1.89401" fill="#D9D9D9"/>
                <circle cx="67.8681" cy="80.9687" r="1.89401" fill="#D9D9D9"/>
                <circle cx="67.8681" cy="87.5979" r="1.89401" fill="#D9D9D9"/>
                <circle cx="67.8681" cy="94.227" r="1.89401" fill="#D9D9D9"/>
                <circle cx="67.8681" cy="100.856" r="1.89401" fill="#D9D9D9"/>
                <circle cx="74.497" cy="54.4528" r="1.89401" fill="#D9D9D9"/>
                <circle cx="74.497" cy="61.0818" r="1.89401" fill="#D9D9D9"/>
                <circle cx="74.497" cy="67.7109" r="1.89401" fill="#D9D9D9"/>
                <circle cx="74.497" cy="74.3401" r="1.89401" fill="#D9D9D9"/>
                <circle cx="74.497" cy="80.9687" r="1.89401" fill="#D9D9D9"/>
                <circle cx="74.497" cy="87.5979" r="1.89401" fill="#D9D9D9"/>
                <circle cx="74.497" cy="94.227" r="1.89401" fill="#D9D9D9"/>
                <circle cx="74.497" cy="100.856" r="1.89401" fill="#D9D9D9"/>
                <circle cx="81.1259" cy="54.4528" r="1.89401" fill="#D9D9D9"/>
                <circle cx="81.1259" cy="61.0818" r="1.89401" fill="#D9D9D9"/>
                <circle cx="81.1259" cy="67.7109" r="1.89401" fill="#D9D9D9"/>
                <circle cx="81.1259" cy="74.3401" r="1.89401" fill="#D9D9D9"/>
                <circle cx="81.1259" cy="80.9687" r="1.89401" fill="#D9D9D9"/>
                <circle cx="81.1259" cy="87.5979" r="1.89401" fill="#D9D9D9"/>
                <circle cx="81.1259" cy="94.227" r="1.89401" fill="#D9D9D9"/>
                <circle cx="81.1259" cy="100.856" r="1.89401" fill="#D9D9D9"/>
                <circle cx="87.7551" cy="54.4528" r="1.89401" fill="#D9D9D9"/>
                <circle cx="87.7551" cy="61.0818" r="1.89401" fill="#D9D9D9"/>
                <circle cx="87.7551" cy="67.7109" r="1.89401" fill="#D9D9D9"/>
                <circle cx="87.7551" cy="74.3401" r="1.89401" fill="#D9D9D9"/>
                <circle cx="87.7551" cy="80.9687" r="1.89401" fill="#D9D9D9"/>
                <circle cx="87.7551" cy="87.5979" r="1.89401" fill="#D9D9D9"/>
                <circle cx="87.7551" cy="94.227" r="1.89401" fill="#D9D9D9"/>
                <circle cx="87.7551" cy="100.856" r="1.89401" fill="#D9D9D9"/>
                <circle cx="94.3842" cy="54.4528" r="1.89401" fill="#D9D9D9"/>
                <circle cx="94.3842" cy="61.0818" r="1.89401" fill="#D9D9D9"/>
                <circle cx="94.3842" cy="67.7109" r="1.89401" fill="#D9D9D9"/>
                <circle cx="94.3842" cy="74.3401" r="1.89401" fill="#D9D9D9"/>
                <circle cx="94.3842" cy="80.9687" r="1.89401" fill="#D9D9D9"/>
                <circle cx="94.3842" cy="87.5979" r="1.89401" fill="#D9D9D9"/>
                <circle cx="94.3842" cy="94.227" r="1.89401" fill="#D9D9D9"/>
                <circle cx="94.3842" cy="100.856" r="1.89401" fill="#D9D9D9"/>
                <circle cx="101.013" cy="54.4528" r="1.89401" fill="#D9D9D9"/>
                <circle cx="101.013" cy="61.0818" r="1.89401" fill="#D9D9D9"/>
                <circle cx="101.013" cy="67.7109" r="1.89401" fill="#D9D9D9"/>
                <circle cx="101.013" cy="74.3401" r="1.89401" fill="#D9D9D9"/>
                <circle cx="101.013" cy="80.9687" r="1.89401" fill="#D9D9D9"/>
                <circle cx="101.013" cy="87.5979" r="1.89401" fill="#D9D9D9"/>
                <circle cx="101.013" cy="94.227" r="1.89401" fill="#D9D9D9"/>
                <circle cx="101.013" cy="100.856" r="1.89401" fill="#D9D9D9"/>
                <circle cx="107.642" cy="54.4528" r="1.89401" fill="#D9D9D9"/>
                <circle cx="114.271" cy="54.4528" r="1.89401" fill="#D9D9D9"/>
                <circle cx="107.642" cy="61.0818" r="1.89401" fill="#D9D9D9"/>
                <circle cx="114.271" cy="61.0818" r="1.89401" fill="#D9D9D9"/>
                <circle cx="107.642" cy="67.7109" r="1.89401" fill="#D9D9D9"/>
                <circle cx="114.271" cy="67.7109" r="1.89401" fill="#D9D9D9"/>
                <circle cx="107.642" cy="74.3401" r="1.89401" fill="#D9D9D9"/>
                <circle cx="114.271" cy="74.3401" r="1.89401" fill="#D9D9D9"/>
                <circle cx="107.642" cy="80.9687" r="1.89401" fill="#D9D9D9"/>
                <circle cx="114.271" cy="80.9687" r="1.89401" fill="#D9D9D9"/>
                <circle cx="107.642" cy="87.5979" r="1.89401" fill="#D9D9D9"/>
                <circle cx="114.271" cy="87.5979" r="1.89401" fill="#D9D9D9"/>
                <circle cx="107.642" cy="94.227" r="1.89401" fill="#D9D9D9"/>
                <circle cx="107.642" cy="100.856" r="1.89401" fill="#D9D9D9"/>
                <circle cx="114.271" cy="94.227" r="1.89401" fill="#D9D9D9"/>
                <circle cx="114.271" cy="100.856" r="1.89401" fill="#D9D9D9"/>
                </svg>
        </>

    );
}
