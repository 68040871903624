import React from "react";
import { Box, Container,Grid, Typography, styled } from "@mui/material";
import video from "../../Assets/how-it-work.mp4";
import Image from "../../Assets/image/anywhere-img.png";

import { motion } from "framer-motion";
import CircleBox from "../../Assets/Svgs/circleBox";
import LandingScreenOne from "./landingScreenOne";
import LandingWave from "./landingWave";
import LandingQuotes from "./landingQuotes";
import LandingScreenTwo from "./landingScreenTwo";
import useWindowWidth from "../../utils/useWindowWidth";
import LandingScreenMobile from "./landingScreenMobile";
const ModuleWrapper = styled("section")(() => ({ width: '100%', paddingBlock:'6.25rem',position:'relative',overflow:'hidden',
  '@media (max-width: 550px)': {
    paddingBlock:'2.5rem 1.25rem',
  },
}));
const InfoList = styled("ul")(() => ({padding:0,margin:'0 auto',listStyle:'none',display:'table',
  '& li':{textTransform:'capitalize',fontSize:'3.75rem',lineHeight:1, fontWeight:600, background: 'linear-gradient(271deg, #0044A7 15.15%, #00B7DF 135.36%)',backgroundClip: 'text',WebkitBackgroundClip: 'text',WebkitTextFillColor: 'transparent',}
}));
const VideoWrapper = styled("div")(() => ({width: '25rem', position:'relative',marginBlockStart:'5rem',
  '@media (max-width: 550px)': {
    position:'absolute',width:'35%',right:'1.25rem',top:'1.875rem',marginBlockStart:'0rem',
  },
}));
const CBTop = styled("div")(() => ({width: '7.5rem',height:'6.25rem', position:'absolute',left:'-5%',top:'4%',
  '@media (max-width: 550px)': {
        width: '5rem',height:'3.75rem',right:'0%',bottom:'0%',
  },
}));
const CBBottom = styled("div")(() => ({width: '7.5rem',height:'6.25rem', position:'absolute',right:'2%',bottom:'2%',
'@media (max-width: 550px)': {
  width: '5rem',height:'3.75rem',right:'0%',bottom:'0%',
},
}));
const VideoBox = styled("div")(() => ({height:'37.5rem',borderRadius:'15rem',overflow:'hidden', position:'relative',
  '& video, & img': { width: '100%', height: '100%',objectFit:"cover"},
  '@media (max-width: 550px)': {
    height:'13.75rem',borderRadius:'15rem',
  },
}));
const LeftWave = styled("div")(() => ({width: '32.5rem',height:'10rem', position:'absolute',left:'5%',bottom:'10%',
  '@media (max-width: 550px)': {
    width: '12.5rem',bottom:0,left:'-5%',
  },
}));
const RightWave = styled("div")(() => ({width: '32.5rem',height:'10rem', position:'absolute',right:'-5%',top:'35%',
  '@media (max-width: 550px)': {
    width: '12.5rem',top:'5%',transform:'rotate(-10deg)',
  },
}));
// const btnStyle = {background: 'linear-gradient(to right,  rgba(29,75,194,1) 0%,rgba(5,171,224,1) 100%)',border:'#fff solid 0.0625rem',boxShadow: '4px 3px 15px 0px rgba(36, 80, 121, 0.35)',color:'#fff',paddingInline:'1.125rem',fontSize:'1rem',textTransform:'uppercase'};
const ModuleSticky = { 
  one:{position: 'sticky', top: '6.25rem',},
  // two:{position: 'sticky', top: '25rem',zIndex:3},
  // three:{position: 'sticky', top: '22.5rem',zIndex:3}
}
export default function HowItWork() {
  const { isMobile } = useWindowWidth();
  return (
    <>
      <ModuleWrapper>
        <Container maxWidth="xl" style={ModuleSticky.one}>

          <Box textAlign={'center'}>
            {/* <Box component={'span'} className="chips">
              How It Works
            </Box> */}
            {!isMobile && (<></>)}
            <Typography variant="h2" className="titel text-gradient" mb={0} px={{xs:5,lg:0}}>
              access from any mobile device
            </Typography>
            
          </Box>
          <LeftWave>
            <LandingWave/>
          </LeftWave>
          <RightWave>
            <LandingWave/>
          </RightWave>
          <Grid container py={{xs:2,lg:5}} position={'relative'}>
            {isMobile ?(
              <Grid item xs={12}>
                <LandingScreenMobile/>
              </Grid>
            ):(
              <>
                <Grid item lg={5.5} sx={{position:'relative',zIndex:2}}>
                  <InfoList>
                    <li>
                      anyone
                    </li>
                    <li>
                    anytime
                    </li>
                    <li>
                      anywhere 
                    </li>
                  </InfoList>
                  {/* <Box p={4}>
                    <Fab variant="extended" style={btnStyle}>
                      you are in 
                    </Fab>
                  </Box> */}
                  
                  <Box justifyContent={'flex-end'} alignItems={'flex-start'} display={'flex'} mt={3}>
                    <LandingQuotes/>
                    <LandingScreenOne/>
                  </Box>
                </Grid>
              </>
            )}
            
            <Grid item xs={12} lg={4}>
              <VideoWrapper>
                <CBTop>
                  <CircleBox/>
                </CBTop>
                <CBBottom>
                  <CircleBox/>
                </CBBottom>
                <VideoBox>
                  {!isMobile ? (
                    <motion.video
                      controls={false}
                      autoPlay
                      muted
                      loop
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1}}
                      transition={{ delay: 1, duration: 1 }}
                    >
                      <source src={video} type="video/mp4" />
                    </motion.video>
                  ):(
                    <img src={Image} alt="Image"/>
                  )}
                </VideoBox>
              </VideoWrapper>
            </Grid>
            {!isMobile && (
              <>
                <Grid item lg={2.5}>
                  <LandingScreenTwo/>
                </Grid>
              </>
            )}
          </Grid>
          
        </Container>
        {/* <Container maxWidth="xl" style={ModuleSticky.three}>
          <Grid container>
            <Grid item lg={9.5}>
            </Grid>
            <Grid item lg={2.5}>
              <LandingScreenTwo/>
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth="xl" style={ModuleSticky.two}>
          <Grid container >
            <Grid item lg={5.5} justifyContent={'flex-end'} alignItems={'flex-start'} display={'flex'}>
              <LandingQuotes/>
              <LandingScreenOne/>
            </Grid>
            <Grid item lg={4}>
            </Grid>
            <Grid item lg={2.5}>
            </Grid>
          </Grid>
        </Container> */}
        
      </ModuleWrapper>
    </>
  );
}
