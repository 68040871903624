import React, { useEffect, useRef } from "react";

import { Link } from "react-router-dom";
import GainLogo from "../svg/Gainlogo";
import {  Zoom, styled } from "@mui/material";
// const accent = "#00395f";
const SplashContainer = styled("main")(() => ({
  justifyContent:'center',
  alignItems:'center',
  background: 'linear-gradient(to bottom, #001640 0%, #003D67 100%)',
  display:'flex',
  position:'fixed',
  left:0,
  top:0,
  zIndex:10,
  width:'100%',
  height:'100%',
  overflow:'hidden',
    '&.hide':{
      opacity: 0,
      pointerEvents: 'none',
      transition: 'all 1s',
      transform:'scale(2.5)',
    }

}));

const BrandLogo = styled("div")(() => ({
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  width: 120,
  height: 100,
  marginInline: "auto",
  "& a": {
    width: "100%",
    height: "100%",
  },
}));
const CircleTopLeft = styled("div")(() => ({
  position: "absolute",
  zIndex: 2,
  width: "12.5rem",
  height: "12.5rem",
  borderRadius: "50%",
  backgroundColor: "#00214F",
  left: 0,
  top: "5%",
  transform: "translateX(-50%)",
}));
const CircleBottomRight = styled("div")(() => ({
  position: "absolute",
  zIndex: 2,
  width: "12.5rem",
  height: "12.5rem",
  borderRadius: "50%",
  backgroundColor: "#003054",
  right: 0,
  bottom: "5%",
  transform: "translateX(50%)",
}));


function Splash() {
  const intro = useRef(null);
  useEffect(() => {
    setTimeout(() => {
      intro?.current?.classList?.add("hide");
    }, 1500);
  }, []);
    return (
      <>
        <SplashContainer  ref={intro}>
          <CircleTopLeft/>
          <Zoom in={true} timeout={{enter: 2000, exit: 3000}}>
          <BrandLogo>
            <Link to={`/`} className="dark">
              <GainLogo/>
            </Link>
          </BrandLogo>
          </Zoom>
          <CircleBottomRight />
        </SplashContainer>
      </>
    );
}
export default Splash;
