import React, { createContext, useContext } from "react";
import useCustomerStore from "../stores/useRefreshStore";

const CustomerContext = createContext(null);

export function CustomerContextProvider({ children }) {
  const store = useCustomerStore();

  // Create a context value that mirrors the old context structure
  const contextValue = {
    // Auth related
    token: store.token,
    setToken: store.setToken,
    customer: store.customer,
    setCustomer: store.setCustomer,
    isInvalid: store.isInvalid,

    // Request related
    currentRequest: store.currentRequest,
    setCurrentRequest: store.setCurrentRequest,
    loadFunActivities: store.loadFunActivities,
    setLoadFunActivities: store.setLoadFunActivities,
    isLoading: store.isLoading,
    load: store.isLoading,

    // Payment related
    invoiceData: store.invoiceData,
    setInvoiceData: store.setInvoiceData,
    signatureData: store.signatureData,
    setSignatureData: store.setSignatureData,
    amountArray: store.amountArray,
    setAmountArray: store.setAmountArray,

    // Communication related
    isOtpSent: store.isOtpSent,
    setIsOtpSent: store.setIsOtpSent,
    contactlessRequestDetails: store.contactlessRequestDetails,
    setContactlessRequestDetails: store.setContactlessRequestDetails,
    notifications: store.notifications,
    setNotifications: store.setNotifications,

    // Utility functions - these will be handled by the component itself
    logout: async () => {
      // Implement the minimal required logic
      store.setToken(null);
      store.setCustomer(null);
      window.location.href = "/login";
    },

    handleRefresh: async () => {
      // Implement minimal refresh logic if needed
      return store.customer;
    },

    getCustomer: async (customerId) => {
      // Implement minimal get customer logic if needed
      console.log(customerId);
      return store.customer;
    },
  };

  return (
    <CustomerContext.Provider value={contextValue}>
      {children}
    </CustomerContext.Provider>
  );
}

export const useCustomer = () => {
  const context = useContext(CustomerContext);
  if (!context) {
    throw new Error(
      "useCustomer must be used within a CustomerContextProvider",
    );
  }
  return context;
};
